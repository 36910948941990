import React from "react";
import BannerSection from "../../Components/Banner";
import { Link, useLocation, useParams } from "react-router-dom";
import PDFIcon from "../../assets/Images/pdficon.svg";
import Logo from "../../assets/Images/logo.png";
import BGIMG from "../../assets/Images/b_bg.jpg";
import BannerImg from "../../assets/Images/single.jpg";
import StudentImg from "../../assets/Images/g.jpg";
import { CheckBadgeIcon, StarIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const CountryDetails = () => {
  const { continentName, countryName } = useParams();
  const location = useLocation();
  // const { continentName, countryName } = location.state;

  const countryData = [
    {
      continent: "Africa",
      countries: [
        {
          country: "Nigeria",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/ng.svg",
        },
        {
          country: "South Africa",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/za.svg",
        },
        {
          country: "Egypt",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/eg.svg",
        },
        {
          country: "Kenya",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/ke.svg",
        },
        {
          country: "Ethiopia",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/et.svg",
        },
      ],
    },
    {
      continent: "Antarctica",
      countries: [
        {
          country: "Antarctica",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/aq.svg",
        },
      ],
    },
    {
      continent: "Asia",
      countries: [
        {
          country: "China",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/cn.svg",
        },
        {
          country: "India",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/in.svg",
        },
        {
          country: "Japan",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/jp.svg",
        },
        {
          country: "South Korea",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/kr.svg",
        },
        {
          country: "Indonesia",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/id.svg",
        },
      ],
    },
    {
      continent: "Europe",
      countries: [
        {
          country: "France",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/fr.svg",
        },
        {
          country: "Finland",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/fi.svg",
        },
        {
          country: "Germany",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/de.svg",
        },
        {
          country: "Italy",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/it.svg",
        },
        {
          country: "Ireland",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/ie.svg",
        },
        {
          country: "United Kingdom",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/gb.svg",
        },
        {
          country: "Switzerland",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/ch.svg",
        },
        {
          country: "Spain",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/es.svg",
        },
      ],
    },
    {
      continent: "North-America",
      countries: [
        {
          country: "Canada",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/ca.svg",
        },
        {
          country: "United States",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/us.svg",
        },
        {
          country: "Mexico",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/mx.svg",
        },
        {
          country: "Cuba",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/cu.svg",
        },
        {
          country: "Panama",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/pa.svg",
        },
      ],
    },
    {
      continent: "Oceania",
      countries: [
        {
          country: "Australia",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/au.svg",
        },
        {
          country: "New Zealand",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/nz.svg",
        },
        {
          country: "Fiji",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/fj.svg",
        },
        {
          country: "Papua New Guinea",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/pg.svg",
        },
        {
          country: "Samoa",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/ws.svg",
        },
      ],
    },
    {
      continent: "South-America",
      countries: [
        {
          country: "Brazil",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/br.svg",
        },
        {
          country: "Argentina",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/ar.svg",
        },
        {
          country: "Colombia",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/co.svg",
        },
        {
          country: "Chile",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/cl.svg",
        },
        {
          country: "Peru",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/pe.svg",
        },
      ],
    },
  ];

  const CountryDetails = [
    {
      continent: "Africa",
      countries: [
        {
          country: "Nigeria",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/ng.svg",
          details: {
            title:
              "Study Abroad in Nigeria- The Ultimate Study Abroad Destination",
            description:
              "Nigeria offers a vibrant and dynamic educational environment with a strong emphasis on academic excellence and cultural diversity.",
            why_choose_us: [
              {
                title: "World-Class Education",
                description:
                  "Home to top universities like TUM and LMU, known for their rigorous academic standards and research excellence.",
              },
              {
                title: "No Tuition Fees",
                description:
                  "Most public universities offer tuition-free education for international students, making it an affordable option.",
              },
              {
                title: "Strong Job Market",
                description:
                  "Germany has a robust economy, especially in engineering, technology, and automotive sectors, providing ample job opportunities.",
              },
            ],
            reasons: [
              {
                title: "Prestigious Universities",
                description:
                  "The UK is home to some of the world’s most prestigious universities, including the University of Oxford, the University of Cambridge, and Imperial College London. These institutions are known for their high academic standards, groundbreaking research, and exceptional faculty.",
              },
              {
                title: "Diverse Range of Programs",
                description:
                  "With a wide variety of courses and programs, students can find fields of study that suit their interests. From arts and humanities to science and technology, the UK offers unique and specialized programs not easily found elsewhere.",
              },
              {
                title: "Cultural Diversity",
                description:
                  "The UK is a melting pot of cultures, languages, and traditions. Studying here allows students to engage with a diverse community, participate in cultural events, and build a global network of friends and professionals.",
              },
              {
                title: "Strong Research Opportunities",
                description:
                  "UK universities are at the forefront of research and innovation. Students have the opportunity to work alongside leading researchers and engage in projects that address real-world challenges, enhancing their academic experience.",
              },
              {
                title: "Robust Job Market",
                description:
                  "The UK has a strong economy with numerous job opportunities, particularly in finance, technology, healthcare, and creative industries. International students often benefit from internship programs and post-study work options, allowing them to gain valuable experience.",
              },
              {
                title: "Rich History and Culture",
                description:
                  "Studying in the UK provides access to a wealth of history, art, and culture. From historical landmarks to vibrant arts scenes, students can immerse themselves in experiences that enrich their educational journey.",
              },
            ],
            choosingDescription:
              "Choosing Germany as your study abroad destination not only opens doors to exceptional education but also provides an enriching life experience. With its blend of academic excellence, cultural diversity, and career opportunities, Germany truly stands out as the ultimate destination for international students.",
            top_institutes: [
              {
                name: "Technical University of Munich (TUM)",
                description:
                  "Known for engineering, natural sciences, and technology.",
              },
              {
                name: "Ludwig Maximilian University of Munich (LMU)",
                description:
                  "Offers a wide range of programs across disciplines with a strong research focus.",
              },
              {
                name: "Heidelberg University",
                description:
                  "Germany’s oldest university, famous for its contributions to various fields, especially medicine and humanities.",
              },
              {
                name: "RWTH Aachen University",
                description:
                  "Renowned for its engineering programs and close ties to industry.",
              },
              {
                name: "University of Mannheim",
                description:
                  "Recognized for its business and social sciences programs.",
              },
            ],
            banner_images: [BannerImg],
          },
        },
        {
          country: "South-Africa",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/za.svg",
          details: {
            description:
              "South Africa is renowned for its stunning landscapes and high-quality education, offering a unique blend of cultural and academic experiences.",
            why_choose_us: [
              {
                title: "World-Class Education",
                description:
                  "Home to top universities like TUM and LMU, known for their rigorous academic standards and research excellence.",
              },
              {
                title: "No Tuition Fees",
                description:
                  "Most public universities offer tuition-free education for international students, making it an affordable option.",
              },
              {
                title: "Strong Job Market",
                description:
                  "Germany has a robust economy, especially in engineering, technology, and automotive sectors, providing ample job opportunities.",
              },
            ],
            choosingDescription:
              "Choosing Germany as your study abroad destination not only opens doors to exceptional education but also provides an enriching life experience. With its blend of academic excellence, cultural diversity, and career opportunities, Germany truly stands out as the ultimate destination for international students.",
            top_institutes: [
              {
                name: "Technical University of Munich (TUM)",
                description:
                  "Known for engineering, natural sciences, and technology.",
              },
              {
                name: "Ludwig Maximilian University of Munich (LMU)",
                description:
                  "Offers a wide range of programs across disciplines with a strong research focus.",
              },
              {
                name: "Heidelberg University",
                description:
                  "Germany’s oldest university, famous for its contributions to various fields, especially medicine and humanities.",
              },
              {
                name: "RWTH Aachen University",
                description:
                  "Renowned for its engineering programs and close ties to industry.",
              },
              {
                name: "University of Mannheim",
                description:
                  "Recognized for its business and social sciences programs.",
              },
            ],
            banner_images: [BannerImg],
          },
        },
        {
          country: "Egypt",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/eg.svg",
          details: {
            description:
              "Egypt is known for its ancient history and modern educational institutions, providing students with a rich cultural and academic experience.",
            why_choose_us: [
              {
                title: "World-Class Education",
                description:
                  "Home to top universities like TUM and LMU, known for their rigorous academic standards and research excellence.",
              },
              {
                title: "No Tuition Fees",
                description:
                  "Most public universities offer tuition-free education for international students, making it an affordable option.",
              },
              {
                title: "Strong Job Market",
                description:
                  "Germany has a robust economy, especially in engineering, technology, and automotive sectors, providing ample job opportunities.",
              },
            ],
            reasons: [
              {
                title: "Prestigious Universities",
                description:
                  "The UK is home to some of the world’s most prestigious universities, including the University of Oxford, the University of Cambridge, and Imperial College London. These institutions are known for their high academic standards, groundbreaking research, and exceptional faculty.",
              },
              {
                title: "Diverse Range of Programs",
                description:
                  "With a wide variety of courses and programs, students can find fields of study that suit their interests. From arts and humanities to science and technology, the UK offers unique and specialized programs not easily found elsewhere.",
              },
              {
                title: "Cultural Diversity",
                description:
                  "The UK is a melting pot of cultures, languages, and traditions. Studying here allows students to engage with a diverse community, participate in cultural events, and build a global network of friends and professionals.",
              },
              {
                title: "Strong Research Opportunities",
                description:
                  "UK universities are at the forefront of research and innovation. Students have the opportunity to work alongside leading researchers and engage in projects that address real-world challenges, enhancing their academic experience.",
              },
              {
                title: "Robust Job Market",
                description:
                  "The UK has a strong economy with numerous job opportunities, particularly in finance, technology, healthcare, and creative industries. International students often benefit from internship programs and post-study work options, allowing them to gain valuable experience.",
              },
              {
                title: "Rich History and Culture",
                description:
                  "Studying in the UK provides access to a wealth of history, art, and culture. From historical landmarks to vibrant arts scenes, students can immerse themselves in experiences that enrich their educational journey.",
              },
            ],
            choosingDescription:
              "Choosing Germany as your study abroad destination not only opens doors to exceptional education but also provides an enriching life experience. With its blend of academic excellence, cultural diversity, and career opportunities, Germany truly stands out as the ultimate destination for international students.",
            top_institutes: [
              {
                name: "Technical University of Munich (TUM)",
                description:
                  "Known for engineering, natural sciences, and technology.",
              },
              {
                name: "Ludwig Maximilian University of Munich (LMU)",
                description:
                  "Offers a wide range of programs across disciplines with a strong research focus.",
              },
              {
                name: "Heidelberg University",
                description:
                  "Germany’s oldest university, famous for its contributions to various fields, especially medicine and humanities.",
              },
              {
                name: "RWTH Aachen University",
                description:
                  "Renowned for its engineering programs and close ties to industry.",
              },
              {
                name: "University of Mannheim",
                description:
                  "Recognized for its business and social sciences programs.",
              },
            ],
            banner_images: [BannerImg],
          },
        },
      ],
    },
    {
      continent: "Europe",
      countries: [
        {
          country: "Ireland",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/ie.svg",
          details: {
            title: "Study Abroad in Ireland: A Premier Academic Destination",
            description:
              "Ireland offers a unique blend of rich cultural heritage and world-class education, making it a top destination for international students. With globally recognized universities and a vibrant student life, Ireland provides the ideal environment to thrive academically and personally.",
              why_choose_us: [
                {
                  title: "Top-Quality Education",
                  description: "Ireland boasts internationally recognized universities known for academic excellence, innovation, and research in various fields."
                },
                {
                  title: "English-Speaking Country",
                  description: "With English as the primary language, studying in Ireland offers a smooth transition for international students and enhances global career prospects."
                },
                {
                  title: "Thriving Job Market",
                  description: "Ireland is home to European headquarters of global giants like Google, Microsoft, and Apple, providing excellent internship and job opportunities."
                },
                {
                  title: "Post-Study Work Opportunities",
                  description: "Ireland offers post-study work visas, allowing international students to gain valuable work experience after graduation."
                }
              ],
            reasons: [
              {
                title: "English-Speaking Country",
                description: "As an English-speaking nation, Ireland offers an easier transition for international students and opens doors to global opportunities."
              },
              {
                title: "Hub for Global Companies",
                description: "Many leading global companies in tech, pharmaceuticals, and finance (like Google, Facebook, and Pfizer) have their European headquarters in Ireland, offering excellent career opportunities post-graduation."
              },
              {
                title: "Welcoming and Safe Environment",
                description: "Ireland is known for its friendly locals, making it one of the most welcoming and safe countries for international students."
              },
              {
                title: "Work and Study Options",
                description: "International students are allowed to work part-time during their studies, and Ireland offers post-study work visas, providing a gateway to European career opportunities."
              }
            ],
            coursetitle:
              "Popular Courses",
            courselist: [
              "Business and Management",
              "Computer Science and IT",
              "Pharmaceutical Sciences",
              "Nursing and Healthcare",
              "Agriculture and Environmental Studies",
            ],
            choosingDescription:
              "Choosing Ireland as your study abroad destination not only opens doors to exceptional education but also provides an enriching life experience. With its blend of academic excellence, cultural diversity, and career opportunities, Ireland truly stands out as the ultimate destination for international students.",
              top_institutes: [
                {
                  name: "Trinity College Dublin",
                  description: "Known for its excellence in research and innovation, Trinity offers a wide range of undergraduate and postgraduate courses."
                },
                {
                  name: "University College Dublin (UCD)",
                  description: "A prestigious institution known for its global research impact and strong business, law, and engineering programs."
                },
                {
                  name: "National University of Ireland, Galway (NUI Galway)",
                  description: "Renowned for its research in biomedical sciences, NUI Galway also offers courses in arts, business, and social sciences."
                },
                {
                  name: "University College Cork (UCC)",
                  description: "Offering a dynamic academic environment, UCC excels in food science, business, and environmental studies."
                },
                {
                  name: "Dublin City University (DCU)",
                  description: "DCU is a leader in business and communication studies, with strong links to industry and a focus on practical skills."
                }
              ],
            banner_images: [
              "https://images.unsplash.com/photo-1557336685-ba9c1484121a?q=80&w=1924&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            ],
          },
        },
        {
          country: "Finland",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/gb.svg",
          details: {
            title: "Study Abroad in Finland: A Premier Academic Destination",
            description:
              "Finland offers a cutting-edge education system, renowned for its innovative teaching methods, research opportunities, and student-centric approach. Whether you're pursuing a degree in technology, business, education, or design, Finland's universities are consistently ranked among the best globally. Experience high-quality education in a welcoming and progressive environment, with the added benefit of studying in English while immersing yourself in one of the happiest countries in the world.",
            why_choose_us: [
              {
                title: "World-Class Education",
                description:
                  "Finland is renowned for its high-quality education system, consistently ranked among the best in the world.",
              },
              {
                title: "Wide Range of English-Taught Programs",
                description:
                  "Many universities offer diverse degree programs taught entirely in English, especially in fields like business, technology, and health sciences.",
              },
              {
                title: "Affordable Education",
                description:
                  "For EU/EEA students, education is often free, and international students benefit from affordable tuition compared to other European countries.",
              },
              {
                title: "Safe and Welcoming Environment",
                description:
                  "Finland is one of the safest countries globally, with a high quality of life and a strong focus on equality and sustainability.",
              },
              {
                title: "Post-Graduation Opportunities",
                description:
                  "International students can stay in Finland after graduation with a post-study visa, making it easier to explore job opportunities.",
              },
            ],
            reasons: [
              {
                title: "Top-Ranked Education System",
                description: "Finland's education system is renowned for its quality and emphasis on innovation, consistently ranking among the best globally."
              },
              {
                title: "Wide Range of English-Taught Programs",
                description: "Many universities in Finland offer a variety of programs in English, catering to international students across disciplines."
              },
              {
                title: "Affordable Living and Study Costs",
                description: "Tuition fees in Finland are lower compared to other European countries, and scholarships are widely available to international students."
              },
              {
                title: "High Quality of Life",
                description: "Finland is famous for its clean environment, low crime rates, and excellent healthcare, ensuring a comfortable and safe living experience."
              },
            ],
            coursetitle:
              "Popular Courses",
            courselist: [
              "Data Science",
              "International Business ",
              "Biomedical Sciences",
              "Creative Sustainability",
              "Public and Global Health",
            ],
            choosingDescription:
              "Choosing Finland as your study abroad destination not only opens doors to exceptional education but also provides an enriching life experience. With its blend of academic excellence, cultural diversity, and career opportunities, Finland truly stands out as the ultimate destination for international students.",
            top_institutes: [
              {
                name: "University of Helsinki",
                description: "A leader in research and education, offering a wide range of multidisciplinary programs."
              },
              {
                name: "Aalto University",
                description: "Known for its emphasis on innovation and creativity, particularly in business, arts, and engineering."
              },
              {
                name: "University of Turku",
                description: "A vibrant academic community known for its excellence in biomedical sciences, humanities, and law."
              },
              {
                name: "Tampere University",
                description: "Specializes in technology and health-related fields, with a growing reputation for game studies and digital innovations."
              }
            ],
            banner_images: [
              "https://images.unsplash.com/photo-1522885147691-06d859633fb8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            ],
          },
        },
        {
          country: "United-Kingdom",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/gb.svg",
          details: {
            title: "Study Abroad in the UK: A Premier Academic Destination",
            description:
              "The United Kingdom is renowned for its rich educational heritage, vibrant culture, and global influence. Here’s why studying in the UK is an excellent choice for international students.",
            why_choose_us: [
              {
                title: "Prestigious Universities",
                description:
                  "Institutions like Oxford and Cambridge offer high academic standards and a rich cultural heritage.",
              },
              {
                title: "Diverse Range of Programs",
                description:
                  "Extensive course offerings across various fields, accommodating diverse interests.",
              },
              {
                title: "Cultural Diversity",
                description:
                  "The UK is a multicultural society, providing a rich social experience.",
              },
            ],
            reasons: [
              {
                title: "Prestigious Universities",
                description:
                  "The UK is home to some of the world’s most prestigious universities, including the University of Oxford, the University of Cambridge, and Imperial College London. These institutions are known for their high academic standards, groundbreaking research, and exceptional faculty.",
              },
              {
                title: "Diverse Range of Programs",
                description:
                  "With a wide variety of courses and programs, students can find fields of study that suit their interests. From arts and humanities to science and technology, the UK offers unique and specialized programs not easily found elsewhere.",
              },
              {
                title: "Cultural Diversity",
                description:
                  "The UK is a melting pot of cultures, languages, and traditions. Studying here allows students to engage with a diverse community, participate in cultural events, and build a global network of friends and professionals.",
              },
              {
                title: "Strong Research Opportunities",
                description:
                  "UK universities are at the forefront of research and innovation. Students have the opportunity to work alongside leading researchers and engage in projects that address real-world challenges, enhancing their academic experience.",
              },
              {
                title: "Robust Job Market",
                description:
                  "The UK has a strong economy with numerous job opportunities, particularly in finance, technology, healthcare, and creative industries. International students often benefit from internship programs and post-study work options, allowing them to gain valuable experience.",
              },
              {
                title: "Rich History and Culture",
                description:
                  "Studying in the UK provides access to a wealth of history, art, and culture. From historical landmarks to vibrant arts scenes, students can immerse themselves in experiences that enrich their educational journey.",
              },
            ],
            coursetitle:
              "Popular Courses",
            courselist: [
              "QTS - This course is for Teachers in UK",
              "MBA in Healthcare & Logistics/Supply Chain ",
              "Pharmacist in UK",
              "Data Science ",
              "Data Security ",
              "Bio Informatics ",
              "Biomedical ",
              "Bio technology ",
              "Computer Science ",
              "Cosmetic Science",
            ],
            choosingDescription:
              "Choosing UK as your study abroad destination not only opens doors to exceptional education but also provides an enriching life experience. With its blend of academic excellence, cultural diversity, and career opportunities, UK truly stands out as the ultimate destination for international students.",
            top_institutes: [
              {
                name: "University of Oxford",
                description:
                  "One of the oldest and most prestigious universities in the world, known for its rigorous academics.",
              },
              {
                name: "University of Cambridge",
                description:
                  "Renowned for its excellence in various fields, particularly science, literature, and humanities.",
              },
              {
                name: "Imperial College London",
                description:
                  "Specializes in science, engineering, and medicine, with a strong focus on research.",
              },
              {
                name: "London School of Economics and Political Science (LSE)",
                description:
                  "Famous for its social science programs and global research impact.",
              },
              {
                name: "University College London (UCL)",
                description:
                  "Offers a diverse range of programs and is known for its research and innovation.",
              },
            ],
            banner_images: [
              "https://images.unsplash.com/photo-1660814065563-943aada21d2e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            ],
          },
        },
        {
          country: "Switzerland",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/ch.svg",
          details: {
            title: "Study Abroad in Switzerland: A Unique Academic Experience",
            description:
              "Switzerland stands out as a premier destination for students seeking high-quality education in a multicultural environment. Here’s why studying in Switzerland is a great choice:",
            why_choose_us: [
              {
                title: "High-Quality Education",
                description:
                  "Renowned for academic excellence, particularly in fields like finance, hospitality, and engineering.",
              },
              {
                title: "Multilingual Environment",
                description:
                  "Offers a unique opportunity to learn multiple languages in a culturally rich setting.",
              },
              {
                title: "Strong Research Focus",
                description:
                  "Swiss universities emphasize research and innovation, providing hands-on learning opportunities.",
              },
            ],
            reasons: [
              {
                title: "High-Quality Education",
                description:
                  "Switzerland is known for its world-class education system and is home to several top-ranking universities, such as ETH Zurich and the University of Zurich. These institutions are recognized for their academic excellence and cutting-edge research.",
              },
              {
                title: "Multilingual Environment",
                description:
                  "With four official languages (German, French, Italian, and Romansh), Switzerland offers a unique opportunity to learn multiple languages while studying. This linguistic diversity enriches the educational experience and prepares students for global careers.",
              },
              {
                title: "Strong Focus on Research and Innovation",
                description:
                  "Swiss universities are known for their emphasis on research, particularly in fields such as science, technology, and finance. Students have access to state-of-the-art facilities and opportunities to participate in groundbreaking research projects.",
              },
              {
                title: "Vibrant International Community",
                description:
                  "Switzerland attracts students from around the world, creating a vibrant and diverse academic community. This multicultural environment fosters collaboration and exchange of ideas, enhancing the overall learning experience.",
              },
              {
                title: "High Standard of Living",
                description:
                  "Switzerland is known for its high quality of life, safe environment, and stunning natural landscapes. Students can enjoy a well-rounded lifestyle, with access to outdoor activities, cultural experiences, and excellent public services.",
              },
              {
                title: "Strong Job Prospects",
                description:
                  "Switzerland has a robust economy and is home to numerous multinational companies, particularly in finance, pharmaceuticals, and engineering. International students benefit from excellent internship opportunities and post-study work options.",
              },
            ],
            choosingDescription:
              "Choosing Switzerland as your study abroad destination offers a unique blend of high-quality education, cultural richness, and beautiful surroundings, making it an exceptional choice for international students.",
            top_institutes: [
              {
                name: "ETH Zurich (Swiss Federal Institute of Technology)",
                description:
                  "Renowned for its engineering and technology programs.",
              },
              {
                name: "University of Zurich",
                description:
                  "Offers a wide range of programs and is known for its research contributions.",
              },
              {
                name: "University of Geneva",
                description:
                  "Known for its international focus and programs in humanities and social sciences.",
              },
              {
                name: "IMD Business School",
                description:
                  "A top-ranked business school offering MBA and executive education programs.",
              },
              {
                name: "Ecole Hôtelière de Lausanne (EHL)",
                description:
                  "A leading institution for hospitality management, known for its practical approach.",
              },
            ],
            banner_images: [
              "https://images.unsplash.com/photo-1477271706509-fecda7438b68?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            ],
          },
        },
        {
          country: "Germany",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/gb.svg",
          details: {
            title:
              "Study Abroad in Germany- The Ultimate Study Abroad Destination",
            description:
              "Germany has emerged as a top choice for international students seeking high-quality education in a diverse and vibrant environment. Here’s why studying in Germany is an excellent opportunity.",
            why_choose_us: [
              {
                title: "World-Class Education",
                description:
                  "Home to top universities like TUM and LMU, known for their rigorous academic standards and research excellence.",
              },
              {
                title: "No Tuition Fees",
                description:
                  "Most public universities offer tuition-free education for international students, making it an affordable option.",
              },
              {
                title: "Strong Job Market",
                description:
                  "Germany has a robust economy, especially in engineering, technology, and automotive sectors, providing ample job opportunities.",
              },
            ],
            reasons: [
              {
                title: "World-Class Education",
                description:
                  "Germany is home to some of the world’s leading universities, known for their rigorous academic standards and innovative research. Institutions such as the Technical University of Munich, Ludwig Maximilian University of Munich, and Heidelberg University consistently rank among the best globally. The country offers a wide range of programs in English, particularly at the master's level, making it accessible for non-German speakers.",
              },
              {
                title: "No Tuition Fees at Public Universities",
                description:
                  "One of the most appealing aspects of studying in Germany is the low cost of education. Most public universities do not charge tuition fees for international students, making it an affordable option compared to other popular study destinations. Students may only need to cover a small administrative fee each semester.",
              },
              {
                title: "Strong Focus on Research and Innovation",
                description:
                  "Germany is renowned for its emphasis on research and development. Students have the opportunity to engage in cutting-edge research projects, often collaborating with industry leaders. This hands-on experience enhances learning and prepares students for competitive job markets.",
              },
              {
                title: "Diverse Culture and Language",
                description:
                  "Studying in Germany offers a chance to immerse yourself in a rich cultural landscape. With its historical sites, vibrant cities, and diverse communities, students can explore a wide range of cultural experiences. Additionally, learning German can be a valuable asset, enhancing career prospects in Europe and beyond.",
              },
              {
                title: "Robust Job Market",
                description:
                  "Germany has a strong economy and is known for its engineering, automotive, and technology sectors. International students benefit from numerous internship opportunities and job placements. Graduates often find it easier to secure employment due to the country’s favourable immigration policies for skilled workers.",
              },
              {
                title: "Support for International Students",
                description:
                  "German universities provide extensive support services for international students, including orientation programs, language courses, and counselling services. This support network helps students adjust to their new environment and succeed academically.",
              },
            ],
            applytitle: "Where Can You Apply In Germany?",
            universities: [
              {
                type: "Public University",
                description:
                  "Public universities in Germany are universities that are owned and operated by the federal government or the state government. These universities are funded by the government and do not have any private ownership or control. Public universities in Germany offer a wide range of degree programs and courses, and they are generally known for their high academic standards and research-oriented approach.",
                tuition:
                  "The tuition fees at public universities in Germany are generally low or free of charge, which makes them accessible to a wide range of students.",
                accessibility:
                  "Public universities in Germany are open to both domestic and international students, and they typically offer a diverse and multicultural environment for learning and research.",
              },
              {
                type: "Private University",
                description:
                  "Private universities in Germany are universities that are owned and operated by private organizations or individuals, rather than by the state or government. Private universities in Germany must meet the same standards and quality requirements as public universities, and are subject to the same accreditation process. They offer a wide range of undergraduate and graduate degree programs, and often have a more specialized focus than public universities.",
                tuition:
                  "Smaller class sizes, which can allow for more individualized attention and a more personalized learning experience.",
                accessibility:
                  "More flexible schedules and course offerings, allowing students to pursue their degrees while balancing work or other responsibilities.",
              },
            ],
            choosingDescription:
              "Choosing Germany as your study abroad destination not only opens doors to exceptional education but also provides an enriching life experience. With its blend of academic excellence, cultural diversity, and career opportunities, Germany truly stands out as the ultimate destination for international students.",
            top_institutes: [
              {
                name: "Technical University of Munich (TUM)",
                description:
                  "Known for engineering, natural sciences, and technology.",
              },
              {
                name: "Ludwig Maximilian University of Munich (LMU)",
                description:
                  "Offers a wide range of programs across disciplines with a strong research focus.",
              },
              {
                name: "Heidelberg University",
                description:
                  "Germany’s oldest university, famous for its contributions to various fields, especially medicine and humanities.",
              },
              {
                name: "RWTH Aachen University",
                description:
                  "Renowned for its engineering programs and close ties to industry.",
              },
              {
                name: "University of Mannheim",
                description:
                  "Recognized for its business and social sciences programs.",
              },
            ],
            banner_images: [
              "https://plus.unsplash.com/premium_photo-1688479424258-48fc0da30d53?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            ],
          },
        },
      ],
    },
    {
      continent: "North-America",
      countries: [
        {
          country: "Canada",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/ca.svg",
          details: {
            title:
              "Study Abroad in Canada- The Ultimate Study Abroad Destination",
            description:
              "Score 8+ Bands with Certified and experienced Benchmark Coach. When it comes to world-class education, Canada stands as a beacon of excellence, offering a welcoming environment, top-tier institutions, and a diverse cultural tapestry. Studying in Canada isn’t just about gaining a degree. It’s about immersing yourself in an enriching educational experience that prepares you for global success. Here’s a glimpse into what makes studying in Canada.",
            why_choose_us: [
              {
                title: "World-Class Education",
                description:
                  "Home to top universities like TUM and LMU, known for their rigorous academic standards and research excellence.",
              },
              {
                title: "No Tuition Fees",
                description:
                  "Most public universities offer tuition-free education for international students, making it an affordable option.",
              },
              {
                title: "Strong Job Market",
                description:
                  "Germany has a robust economy, especially in engineering, technology, and automotive sectors, providing ample job opportunities.",
              },
            ],
            reasons: [
              {
                title: "Prestigious Universities",
                description:
                  "The UK is home to some of the world’s most prestigious universities, including the University of Oxford, the University of Cambridge, and Imperial College London. These institutions are known for their high academic standards, groundbreaking research, and exceptional faculty.",
              },
              {
                title: "Diverse Range of Programs",
                description:
                  "With a wide variety of courses and programs, students can find fields of study that suit their interests. From arts and humanities to science and technology, the UK offers unique and specialized programs not easily found elsewhere.",
              },
              {
                title: "Cultural Diversity",
                description:
                  "The UK is a melting pot of cultures, languages, and traditions. Studying here allows students to engage with a diverse community, participate in cultural events, and build a global network of friends and professionals.",
              },
              {
                title: "Strong Research Opportunities",
                description:
                  "UK universities are at the forefront of research and innovation. Students have the opportunity to work alongside leading researchers and engage in projects that address real-world challenges, enhancing their academic experience.",
              },
              {
                title: "Robust Job Market",
                description:
                  "The UK has a strong economy with numerous job opportunities, particularly in finance, technology, healthcare, and creative industries. International students often benefit from internship programs and post-study work options, allowing them to gain valuable experience.",
              },
              {
                title: "Rich History and Culture",
                description:
                  "Studying in the UK provides access to a wealth of history, art, and culture. From historical landmarks to vibrant arts scenes, students can immerse themselves in experiences that enrich their educational journey.",
              },
            ],
            choosingDescription:
              "Choosing Germany as your study abroad destination not only opens doors to exceptional education but also provides an enriching life experience. With its blend of academic excellence, cultural diversity, and career opportunities, Germany truly stands out as the ultimate destination for international students.",
            top_institutes: [
              {
                name: "Technical University of Munich (TUM)",
                description:
                  "Known for engineering, natural sciences, and technology.",
              },
              {
                name: "Ludwig Maximilian University of Munich (LMU)",
                description:
                  "Offers a wide range of programs across disciplines with a strong research focus.",
              },
              {
                name: "Heidelberg University",
                description:
                  "Germany’s oldest university, famous for its contributions to various fields, especially medicine and humanities.",
              },
              {
                name: "RWTH Aachen University",
                description:
                  "Renowned for its engineering programs and close ties to industry.",
              },
              {
                name: "University of Mannheim",
                description:
                  "Recognized for its business and social sciences programs.",
              },
            ],
            banner_images: [BannerImg],
          },
        },
        {
          country: "United-States",
          image:
            "https://github.com/hampusborgos/country-flags/raw/main/svg/us.svg",
          details: {
            title: "Study Abroad in US- The Ultimate Study Abroad Destination",
            description:
              "The United States offers a diverse and comprehensive educational experience with some of the world's best universities and a rich cultural landscape.",
            why_choose_us: [
              {
                title: "World-Class Education",
                description:
                  "Home to top universities like TUM and LMU, known for their rigorous academic standards and research excellence.",
              },
              {
                title: "No Tuition Fees",
                description:
                  "Most public universities offer tuition-free education for international students, making it an affordable option.",
              },
              {
                title: "Strong Job Market",
                description:
                  "Germany has a robust economy, especially in engineering, technology, and automotive sectors, providing ample job opportunities.",
              },
            ],
            reasons: [
              {
                title: "Prestigious Universities",
                description:
                  "The UK is home to some of the world’s most prestigious universities, including the University of Oxford, the University of Cambridge, and Imperial College London. These institutions are known for their high academic standards, groundbreaking research, and exceptional faculty.",
              },
              {
                title: "Diverse Range of Programs",
                description:
                  "With a wide variety of courses and programs, students can find fields of study that suit their interests. From arts and humanities to science and technology, the UK offers unique and specialized programs not easily found elsewhere.",
              },
              {
                title: "Cultural Diversity",
                description:
                  "The UK is a melting pot of cultures, languages, and traditions. Studying here allows students to engage with a diverse community, participate in cultural events, and build a global network of friends and professionals.",
              },
              {
                title: "Strong Research Opportunities",
                description:
                  "UK universities are at the forefront of research and innovation. Students have the opportunity to work alongside leading researchers and engage in projects that address real-world challenges, enhancing their academic experience.",
              },
              {
                title: "Robust Job Market",
                description:
                  "The UK has a strong economy with numerous job opportunities, particularly in finance, technology, healthcare, and creative industries. International students often benefit from internship programs and post-study work options, allowing them to gain valuable experience.",
              },
              {
                title: "Rich History and Culture",
                description:
                  "Studying in the UK provides access to a wealth of history, art, and culture. From historical landmarks to vibrant arts scenes, students can immerse themselves in experiences that enrich their educational journey.",
              },
            ],
            choosingDescription:
              "Choosing Germany as your study abroad destination not only opens doors to exceptional education but also provides an enriching life experience. With its blend of academic excellence, cultural diversity, and career opportunities, Germany truly stands out as the ultimate destination for international students.",
            top_institutes: [
              {
                name: "Technical University of Munich (TUM)",
                description:
                  "Known for engineering, natural sciences, and technology.",
              },
              {
                name: "Ludwig Maximilian University of Munich (LMU)",
                description:
                  "Offers a wide range of programs across disciplines with a strong research focus.",
              },
              {
                name: "Heidelberg University",
                description:
                  "Germany’s oldest university, famous for its contributions to various fields, especially medicine and humanities.",
              },
              {
                name: "RWTH Aachen University",
                description:
                  "Renowned for its engineering programs and close ties to industry.",
              },
              {
                name: "University of Mannheim",
                description:
                  "Recognized for its business and social sciences programs.",
              },
            ],
            banner_images: [BannerImg],
          },
        },
      ],
    },
  ];

  const continentData = CountryDetails?.find(
    (continent) =>
      continent?.continent.toLowerCase() === continentName.toLowerCase()
  );

  const data = continentData?.countries?.find(
    (country) => country?.country.toLowerCase() === countryName.toLowerCase()
  );

  return (
    <>
      <Helmet>
        <title className="capitalize">{continentName}</title>
      </Helmet>
      <BannerSection title={countryName} />
      <section className="flex flex-col items-center justify-center font-Jakarta">
        <div className="w-[90%] grid lg:grid-cols-[1fr,3fr] grid-cols-1 gap-5 py-16">
          <div className=" w-full flex flex-col items-center  space-y-10 lg:order-1 xl:order-1 order-2">
            <div className=" w-full px-5 py-5 bg-background h-fit rounded-xl">
              <div className="flex flex-col w-full items-center space-y-3">
                {countryData
                  .find(
                    (obj, index) =>
                      obj.continent.toLowerCase() === continentName
                  )
                  .countries.map((item, index) => (
                    <Link
                      key={index}
                      to={`/countries/${continentName
                        .replace(/\s+/g, "-")
                        .toLowerCase()}/${item.country
                        .replace(/\s+/g, "-")
                        .toLowerCase()}/`}
                      className="w-full"
                    >
                      <motion.button
                        initial={{ opacity: 0, translateY: 150 }}
                        whileInView={{ opacity: 1, translateY: 0 }}
                        viewport={{ once: false }}
                        transition={{ ease: "easeInOut", duration: 0.8 }}
                        className="w-full h-[60px] hover:bg-primary bg-white border rounded-lg font-bold flex flex-row items-center justify-between px-5 text-[#787b84] hover:text-white"
                      >
                        <span>{item.country}</span>
                        <div className="h-[40px] w-[40px]">
                          <img
                            src={item.image}
                            alt=""
                            className="h-full w-full rounded-full border"
                            style={{ objectFit: "cover" }}
                          />
                        </div>
                      </motion.button>
                    </Link>
                  ))}
              </div>
            </div>

            <div className="flex flex-col space-y-2 h-[250px] bg-background rounded-xl w-full p-5">
              <h3 className="text-[25px] font-medium">Downloads</h3>
              <div className="flex flex-row justify-between w-full space-x-5 ">
                <div className="h-[150px] w-1/2 bg-white flex flex-col items-center justify-center relative rounded-lg">
                  <div className="flex flex-col items-center justify-center space-y-2">
                    <div className="bg-primary p-2 rounded-full h-[40px] w-[40px] flex flex-col items-center justify-center">
                      <img src={PDFIcon} alt="" />
                    </div>
                    <h5 className="font-semibold text-[14px] h-[45px] text-center">
                      Privacy Policy
                    </h5>
                  </div>
                  <div className="absolute bottom-0 border-t w-full flex flex-row items-center justify-center p-1">
                    <span className="text-center text-xs">3.9 KB</span>
                  </div>
                </div>
                <div className="h-[150px] w-1/2 bg-white flex flex-col items-center justify-center relative rounded-lg">
                  <div className="flex flex-col items-center justify-center space-y-2">
                    <div className="bg-primary p-2 rounded-full h-[40px] w-[40px] flex flex-col items-center justify-center">
                      <img src={PDFIcon} alt="" />
                    </div>
                    <h5 className="font-semibold text-[14px] h-[45px] text-center">
                      Terms and conditions
                    </h5>
                  </div>
                  <div className="absolute bottom-0 border-t w-full flex flex-row items-center justify-center p-1">
                    <span className="text-center text-xs">3.9 KB</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex flex-col space-y-2 h-[350px] bg-background rounded-xl w-full p-5"
              style={{
                background: `url(${BGIMG})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="flex flex-col items-center justify-center space-y-10 h-full">
                <img src={Logo} alt="" />
                <h3 className="text-center font-bold text-[22px]">
                  Assured Approval-
                  <br />
                  Guaranteed
                </h3>
                <Link to={"/"}>
                  <button className="bg-primary px-5 py-3 text-white rounded-xl">
                    Get in Touch
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="  order-1 lg:order-2 xl:order-2 flex flex-col space-y-4">
            <motion.h2
              initial={{ opacity: 0, translateY: 150 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              viewport={{ once: false }}
              transition={{ ease: "easeInOut", duration: 0.8 }}
              className="text-[32px] font-semibold capitalize"
            >
              The {countryName}
            </motion.h2>
            {data?.details?.title ? (
              <motion.h5
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: false }}
                transition={{ ease: "easeInOut", duration: 0.8 }}
                className="text-[24px] font-semibold"
              >
                {data?.details?.title}
              </motion.h5>
            ) : (
              <motion.h5
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: false }}
                transition={{ ease: "easeInOut", duration: 0.8 }}
                className="text-[24px] font-semibold"
              >
                Score 8+ Bands with Certified and experienced BenchMark Coach
              </motion.h5>
            )}

            <p className="text-[#787b84] text-[20px]">
              {data?.details?.description}
            </p>
            <div className="my-5 flex flex-col space-y-5">
              <motion.h2
                initial={{ opacity: 0, translateY: 150 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                viewport={{ once: false }}
                transition={{ ease: "easeInOut", duration: 0.8 }}
                className="text-[32px] font-semibold "
              >Key Reasons to Study in {data?.country}</motion.h2>

              <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-5">
                {data?.details.reasons.map((obj, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, translateY: 150 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    viewport={{ once: false }}
                    transition={{ ease: "easeInOut", duration: 0.8 }}
                    className="lg:h-[350px] h-auto w-full px-5 py-7 border rounded-lg border-l-4 border-l-primary flex flex-col items-start justify-center"
                  >
                    <div className="flex flex-col items-center space-y-5">
                      <CheckBadgeIcon className="h-8 w-8 fill-primary" />
                      <h3 className="font-bold">{obj.title}</h3>
                      <p>{obj.description}</p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0, translateY: 120 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              viewport={{ once: false }}
              transition={{ ease: "easeInOut", duration: 0.8 }}
              className="h-[500px] w-full rounded-2xl"
            >
              <img
                src={data?.details?.banner_images}
                className="h-full w-full object-cover rounded-2xl"
                alt=""
              />
            </motion.div>
            <div className="my-5 flex flex-col space-y-5">
              <motion.h2
                initial={{ opacity: 0, translateY: 150 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                viewport={{ once: false }}
                transition={{ ease: "easeInOut", duration: 0.8 }}
                className="text-[32px] font-semibold "
              >
                Why choose us?
              </motion.h2>

              <div className="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-1 gap-5">
                {data?.details?.why_choose_us.map((obj, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, translateY: 150 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    viewport={{ once: false }}
                    transition={{ ease: "easeInOut", duration: 0.8 }}
                    className="lg:h-[150px] md:h-[200px] h-[250px] w-full px-5 lg:py-7 border rounded-lg border-l-4 border-l-primary flex flex-col items-start justify-center"
                  >
                    <div className="flex lg:flex-row md:flex-row flex-col justify-around h-full  items-center space-x-5">
                      <CheckBadgeIcon className="h-8 w-8 fill-primary" />
                      <h3 className="font-bold">{obj.title}</h3>
                      <p>{obj.description}</p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
            {data?.details?.courselist &&
              data?.details?.courselist.length > 0 && (
                <div className="my-5 flex flex-col space-y-5">
                  <motion.h2
                    initial={{ opacity: 0, translateY: 150 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    viewport={{ once: false }}
                    transition={{ ease: "easeInOut", duration: 0.8 }}
                    className="text-[32px] font-semibold "
                  >
                    {data?.details?.coursetitle}
                  </motion.h2>

                  <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-5">
                    {data.details.courselist.map((obj, index) => (
                      <motion.div
                        key={index} // Add a unique key to each mapped item
                        initial={{ opacity: 0, translateY: 150 }}
                        whileInView={{ opacity: 1, translateY: 0 }}
                        viewport={{ once: false }}
                        transition={{ ease: "easeInOut", duration: 0.8 }}
                        className="h-[100px] w-full px-5 py-7 border rounded-lg border-l-4 border-l-primary flex flex-col items-start justify-center"
                      >
                        <div className="flex flex-row items-center space-x-5">
                          <CheckBadgeIcon className="h-8 w-8 fill-primary" />
                          <h3 className="font-bold">{obj}</h3>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </div>
              )}
            {data?.details?.applytitle &&
              data?.details?.universities.length > 0 && (
                <div className="my-5 flex flex-col space-y-5">
                  <motion.h2
                    initial={{ opacity: 0, translateY: 150 }}
                    whileInView={{ opacity: 1, translateY: 0 }}
                    viewport={{ once: false }}
                    transition={{ ease: "easeInOut", duration: 0.8 }}
                    className="text-[32px] font-semibold "
                  >
                    {data?.details?.applytitle}
                  </motion.h2>

                  <div className="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-1 gap-5">
                    {data.details.universities.map((obj, index) => (
                      <motion.div
                        key={index} // Add a unique key to each mapped item
                        initial={{ opacity: 0, translateY: 150 }}
                        whileInView={{ opacity: 1, translateY: 0 }}
                        viewport={{ once: false }}
                        transition={{ ease: "easeInOut", duration: 0.8 }}
                        className="h-auto w-full px-5 py-7 border rounded-lg border-l-4 border-l-primary flex flex-col items-start justify-center"
                      >
                        <div className="flex flex-col items-start space-y-5">
                          {/* <CheckBadgeIcon className="h-8 w-8 fill-primary" /> */}

                          <h3 className="font-bold">{obj.type}</h3>
                          <p className="text-[0.9rem]">{obj.description}</p>
                          <p className="text-[0.9rem]">{obj.tuition}</p>
                          <p className="text-[0.9rem]">{obj.accessibility}</p>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </div>
              )}

            <div className="my-5 flex flex-col space-y-5">
              <motion.h2
                initial={{ opacity: 0, translateY: 150 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                viewport={{ once: false }}
                transition={{ ease: "easeInOut", duration: 0.8 }}
                className="text-[32px] font-semibold  "
              >
                Top institutes in {data?.country}
              </motion.h2>
              <p className="text-[#787b84] text-[20px]">
                {data?.details?.choosingDescription}
              </p>
              <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2">
                <div className="h-[400px]">
                  <img
                    src={StudentImg}
                    alt=""
                    className="h-full w-full object-contain "
                  />
                </div>
                <ul className="flex flex-col space-y-10">
                  {data?.details?.top_institutes.map((obj, index) => (
                    <li
                      key={index}
                      className="animate-fadeindown grid grid-cols-[1fr,10fr] items-center gap-5 w-full"
                    >
                      <CheckBadgeIcon className="h-7 w-7 fill-primary" />
                      <p className="flex flex-col">
                        {" "}
                        <strong>{obj.name}</strong>{" "}
                        <span>{obj.description}</span>
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CountryDetails;
