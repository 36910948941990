import React from 'react';
// import {
//     ChatAltIcon,
//     GlobeEuropeAfricaIcon,
//     FlagIcon,
//     ClipboardListIcon,
//     CalendarIcon,
//     VideoCameraIcon,
//     QuizIcon,
//     TrophyIcon
//   } from '@heroicons/react/outline';
  import { Helmet } from 'react-helmet'
import {motion} from 'framer-motion'
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { CalendarIcon, ChatBubbleOvalLeftEllipsisIcon, ClipboardDocumentListIcon, FlagIcon, GlobeAltIcon, PencilIcon, TrophyIcon, VideoCameraIcon } from '@heroicons/react/24/outline';

const LanguageTraining = () => {
    const data = {
        "id": "3",
        "heading": "Language Training and Preparation: Your Path to Success",
        "sections": [
          {
            "id": "3.1",
            "title": "IELTS Preparation (IELTS/UKVI)",
            "description": "Our IELTS Preparation (IELTS/UKVI) course offers personalized, one-to-one sessions delivered through call, Google Meet, or WhatsApp. The program includes 10 live speaking sessions, 4 mock tests, and writing corrections with detailed feedback.",
            "icon": ChatBubbleOvalLeftEllipsisIcon,
            "bgColor": "#F7DC6F"
          },
          {
            "id": "3.2",
            "title": "German Language Training",
            "description": "From beginner to advanced, achieve fluency through interactive lessons with native-speaking instructors. Perfect for academic, professional, or personal growth.",
            "icon": GlobeAltIcon,
            "bgColor": "#8BC34A"
          },
          {
            "id": "3.3",
            "title": "Japanese Language Training",
            "description": "Structured to cover essential skills in speaking, listening, reading, and writing, these courses are ideal for students and professionals looking to study, work, or live in Japan.",
            "icon": FlagIcon,
            "bgColor": "#FFC107"
          }
        ],
        "steps": [
          {
            "id": "3.4",
            "title": "Initial Assessment",
            "description": "Determine your current skill level and set personalized learning goals.",
            "icon": ClipboardDocumentListIcon,
            "bgColor": "#C5CAE9"
          },
          {
            "id": "3.5",
            "title": "Enrollment & Scheduling",
            "description": "Confirm course details and schedule live sessions with expert instructors.",
            "icon": CalendarIcon,
            "bgColor": "#000"
          },
          {
            "id": "3.6",
            "title": "Live Interactive Sessions",
            "description": "Participate in tailored lessons with daily writing and speaking practice.",
            "icon": VideoCameraIcon,
            "bgColor": "#4CAF50"
          },
          {
            "id": "3.7",
            "title": "Mock Tests & Regular Practice",
            "description": "Take frequent mock exams and receive feedback to enhance your skills.",
            "icon": PencilIcon,
            "bgColor": "#9C27B0"
          },
          {
            "id": "3.8",
            "title": "Final Review & Exam Preparation",
            "description": "Get ready for your final test with targeted review sessions and ongoing study material access.",
            "icon": TrophyIcon,
            "bgColor": "#8E24AA"
          }
        ]
      }
  return (
    <>
    <Helmet>
      <title>Language Training</title>
    </Helmet>
    <section className=" flex flex-col items-center my-10">
      <div className="w-[90%] flex flex-col space-y-10 ">
        <motion.h2
          initial={{ translateY: -20, opacity: 0 }}
          whileInView={{ opacity: 1, translateY: 0 }}
          viewport={{ once: true }}
          transition={{ ease: "linear", duration: 0.8 }}
          className="text-[30px] lg:text-[50px] md:text-[50px] leading-snug font-semibold z-20 text-center"
        >
          {data.heading}
        </motion.h2>

        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-1 gap-5">
          {data.sections.map((obj, index) => (
            <motion.div
              key={index} // Add a unique key to each mapped item
              initial={{ opacity: 0, translateY: 150 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              viewport={{ once: false }}
              transition={{ ease: "easeInOut", duration: 0.8 }}
              className="h-[350px] w-full px-5 py-10 border rounded-lg border-l-4 border-r-4 border-r-primary border-l-primary flex flex-col items-start justify-center"
            >
              <div className="flex flex-col items-center space-y-5">
                <CheckBadgeIcon className="h-8 w-8 fill-primary" />
                <h3 className="font-bold">{obj.title}</h3>
                <p>{obj.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
    <section className="min-h-screen flex flex-col items-center justify-center">
      <div className="w-[90%] flex flex-col space-y-3 bg-sec">
        <motion.h2
          initial={{ translateY: -20, opacity: 0 }}
          whileInView={{ opacity: 1, translateY: 0 }}
          viewport={{ once: true }}
          transition={{ ease: "linear", duration: 0.8 }}
          className="text-[30px] lg:text-[50px] md:text-[50px] leading-snug font-semibold z-20 text-center"
        >
          Steps
          <span className="text-text"> Involved</span>
        </motion.h2>

        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 z-20 gap-5 my-5 font-Jakarta  place-items-center items-center justify-center ">
          {data.steps.map((service, index) => {
            return (
              <motion.div
                key={index}
                initial={{ opacity: 0, translateY: 120 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                viewport={{ once: false }}
                transition={{ ease: "easeInOut", duration: 0.8 }}
                className="col-span-1  shadow-lg rounded-xl bg-white z-20 lg:h-[320px] h-fit "
              >
                <div className="before:h-[160px] before:bg-[#f4f8fa] before:w-[70px] before:absolute before:-rotate-45 before:overflow-hidden before:-z-[1] before:-top-[39px] before:left-0 before:rounded-[35px] flex flex-col items-start justify-center relative p-[42px] overflow-hidden rounded-xl">
                  <div
                    className={` p-3 rounded-full my-2`}
                    style={{ backgroundColor: `${service.bgColor}` }}
                  >
                    <service.icon className="h-5 w-5 stroke-white " />
                  </div>
                  <div className="flex flex-col space-y-5">
                    <h4 className="text-[24px] font-semibold">
                      {service.title}
                    </h4>
                    <p className="text-[16px] text-text">
                      {service.description}
                    </p>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    </section>
  </>
  )
}

export default LanguageTraining