import React, { useEffect, useState } from "react";
import Construction from "../../assets/Images/construction.png";
import { motion } from "framer-motion";
import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

const Universities = () => {
  const unveristyData = [
    {
      country: "Canada",
      universities: [
        "Acadia",
        "Algoma",
        "Algonquin",
        "Assiniboine",
        "Bow Valley",
        "Brock",
        "C Of New Caledonia",
        "C Of Rockies",
        "Cambrian",
        "Camosun",
        "Canadore",
        "Capilano",
        "CBU",
        "Centennial",
        "Coast Mnt Clg",
        "Conestoga",
        "Confederation",
        "Crandall",
        "Cumberland",
        "Douglas",
        "Durham",
        "Fanshawe",
        "Fleming",
        "Fraser International College (FIC)",
        "GBC",
        "Georgian",
        "Georgian@Toronto",
        "Great Plains",
        "Humber",
        "JIBC",
        "Keyano",
        "King's University College, London",
        "KPU",
        "La Salle",
        "Lakehead - PG",
        "Lakehead - UG",
        "Lakeland",
        "Lambton",
        "Langara",
        "Laurentian",
        "Loyalist",
        "Loyalist Toronto",
        "Medicine Hat College",
        "Memorial University Of Newfoundland",
        "MITT",
        "Mount Allison",
        "MSVU",
        "Niagara",
        "Niagara@Toronto",
        "NIC",
        "Nippising",
        "NLC",
        "Norquest",
        "Northern",
        "Okanagan",
        "Olds",
        "Ontario Tech University",
        "Parkland",
        "Queens University",
        "Red River",
        "Royal Roads University",
        "Ryerson",
        "SAIT",
        "Saskatchewan Polytechnic",
        "Sault",
        "Selkirk",
        "Seneca",
        "Sheridan",
        "St.Clair",
        "St.Lawrence",
        "St.Francis Xavier University",
        "St.Thomas University",
        "Toronto Metropolitan",
        "Trent",
        "Trinity Western",
        "TRU",
        "University Of Canada West",
        "University Of Fraser Valley",
        "University Of Lethbridge",
        "University Of New Brunswick",
        "University Of Regina - PG",
        "University Of Regina - UG",
        "University Of Saskatchewan",
        "University Of Victoria",
        "University Of Waterloo",
        "University Of Windsor",
        "UNBC",
        "UPEI",
        "Vanier",
        "VCC",
        "VFS",
        "VIU",
        "Wilfrid Laurier University",
        "Yorkville",
      ],
    },
    {
      country: "UK",
      universities: [
        "Abertay University",
        "Aberystwyth University",
        "Amity Education Group (Amity Education Services)",
        "Anglia Ruskin University Cambridge and Chelmsford campus",
        "Arts University Bournemouth",
        "Aston University",
        "Bangor University",
        "Bath Spa University",
        "Bishop Grosseteste University",
        "Brunel University London",
        "Canterbury christ church university",
        "Cardiff Metropolitan University",
        "Coventry University",
        "Cranfield University",
        "De Montfort University",
        "Edge Hill University",
        "Edinburgh Napier University",
        "HERIOT-WATT UNIVERSITY",
        "Keele University",
        "Kings College London",
        "LE CORDON BLEU LONDON",
        "Leeds Beckett University",
        "Liverpool John Moores University",
        "London Metropolitan University",
        "London South Bank University",
        "Loughborough University",
        "Manchester Metropolitan University",
        "Middlesex University",
        "Northumbria University Newcastle",
        "Nottingham Trent University",
        "Oxford Brookes University",
        "Plymouth University",
        "Queen's University Belfast",
        "Robert Gordon University",
        "Royal Holloway University of London",
        "Sheffield Hallam University",
        "Solent University",
        "St Mary's University Twickenham London",
        "Swansea University",
        "Teesside University",
        "The University of Aberdeen",
        "The University of Law",
        "The University of Sheffield",
        "Ulster University Birmingham College of Medicine Dentistry",
        "Ulster University Northern Ireland",
        "University College Birmingham",
        "University of Bedfordshire",
        "University of Bournemouth",
        "University Of Brighton",
        "University of Bristol",
        "University of Central Lancashire",
        "University of Chester",
        "University of Chichester",
        "University of Cumbria",
        "University of Derby",
        "University of East Anglia",
        "University of East London",
        "University of Essex",
        "University of Exeter",
        "University of Greenwich",
        "University of Hertfordshire",
        "University of Huddersfield",
        "University of Hull",
        "University of Liverpool",
        "University of Northampton",
        "University of Nottingham",
        "University of Portsmouth",
        "University of Roehampton",
        "University of Salford",
        "University of Strathclyde",
        "University Of Sussex",
        "University of the Creative Arts",
        "University of the West of England, Bristol",
        "University of West London",
        "University of West of Scotland(Scottish Campuses only i.e. Paisley, Ayr, Dumfries and Lanarkshire )",
        "University of Winchester",
        "University of Wolverhampton",
        "University of York",
        "WREXHAM GLYNDWR UNIVERSITY",
      ],
    },
    {
      country: "Ireland",
      universities: [
        "Trinity College Dublin",
        "University Colleg Dublin",
        "Dublin City University",
        "Maynooth University",
        "University of Galway",
        "University College Cork",
        "University Of Limerick",
        "Technological University Dublin",
        "Munster Technological University",
        "South East Technological University",
        "Technological University of the Shannon",
        "Atlantic Technological University , Sligo",
        "Dublin Business School",
        "National College of Ireland",
        "Griffith College",
        "Independent College",
        "IBAT College",
        "Dundalk Institute of Technology",
      ],
    },
    {
      country: "Australia",
      universities: [
        "Australian Institute of Business and Technology",
        "Charles Darwin University",
        "Deakin University",
        "Edith Cowan University",
        "Flinders University",
        "Holmesglen Institute of TAFE [Holmesglen]",
        "IIBIT Federation University",
        "James Cook University Brisbane",
        "La Trobe University",
        "Le Cordon Bleu Australia",
        "Swinburne University of Technology (SWINBURNE) - Sydney Campus",
        "The Hotel School Sydney - Southern Cross University",
        "University of Tasmania",
        "University of Wollongong",
        "Victoria University of Melbourne",
        "Victoria University of Sydney (Applied through ECA)",
        "Western Sydney University",
        "Melbourne Institute of Technology",
        "Kaplan Business School",
        "Reach Community College",
        "Institute of Health and Management - IHM",
        "Institute of Health and Nursing Australia - IHNA",
        "La Trobe University Sydney and La Trobe College Australia (LTUSC)(Pathway programs)",
        "South Australian Institute of Business and Technology (SAIBT)",
        "Edithcowan College",
        "University of Wollongong College",
        "Holmes Institute Pvt Ltd",
        "Western Sydney University International College",
        "Eynesbury College( University of Adelaide and Unisa)",
        "Queensland University of Technology",
      ],
    },
    {
      country: "NZ",
      universities: [
        "Toi Ohomai Institute of Technology Limited",
        "Ara Institute of Canterbury Ltd",
        "Nelson Marlborough Institute of Technology Limited(NMIT)",
        "AIS St Helens",
        "Wellington Institute of Technology",
        "Waikato Institute of Technology (WINTEC)",
        "University of Otago",
        "Unitec Institute of Technology(Unitec)",
        "AUT University",
        "North Tec",
        "Massey University",
        "University of Waikato",
        "Victoria University of Wellington",
        "Manukau Institute of Technology",
        "Lincoln University",
        "University of Canterbury",
        "Otago Polytechnic",
        "Western Institute of Technology at Taranaki , WITT",
        "Whitireia",
        "New Zealand Tertiary College",
        "Pacific International Hotel Management School",
        "ATMC NEW ZEALAND",
        "Southern Institute of Technology",
        "Eastern Institute of Technology",
        "New Zealand School of Education",
        "Universal College of Learning",
      ],
    },
    {
      country: "France",
      universities: [
        "SKEMA Business School",
        "NEOMA Business School",
        "Paris School of Business (PSB)",
        "Rennes Business School",
        "Montpellier Business School",
        "Kedge Business School",
        "EMLV (Leonard De Vinci)",
        "ESSCA",
        "ESILV Engineering School",
        "ISEP",
      ],
    },
    {
      country: "Germany",
      universities: [
        "Arden University",
        "SRH Hochschukeb Berlin GmbH",
        "Munich Business school",
        "ISL Sprachshule",
        "Macromedica University of Applied Science",
        "ISM , International School of Management",
        "Dresden International University",
        "GISMA Business school",
        "University of Applied science Europe",
        "Berlin School of Business and Innovation",
        "EU Business School",
        "IU international University of Applied Science",
      ],
    },
    {
      country: "Sweden",
      universities: [
        "Chalmers University of Technology",
        "Halmstad University",
        "Jönköping University",
        "Linnaeus University",
        "University of Skovde",
        "University West",
      ],
    },
    {
      country: "Latvia",
      universities: [
        "Riga Technical University",
        "Transport and Telecommunication Institute (TSI)",
        "Turiba University",
        "Ventspils University College",
        "Riga Stradins University",
        "University of Latvia",
      ],
    },
    {
      country: "Netherlands",
      universities: ["University of Twente"],
    },
    {
      country: "Spain",
      universities: ["Universidad Católica San Antonio de Murcia", "UCAM"],
    },
    {
      country: "Switzerland",
      universities: [
        "Business and Hotel Management School",
        "Hotel & Tourism Management Institute (HTMI) Switzerland",
        "Culinary Arts Academy",
        "Cesar Ritz Colleges",
        "Swiss Hotel Management School",
        "Hotel Institute Montreux",
        "IHTTI School of Hotel Management",
      ],
    },
    {
      country: "Dubai",
      universities: [
        "Middlesex University",
        "Heriot-Watt University",
        "University of Stirling",
        "University of Wollongong",
        "Amity Education Group",
        "Manipal Academy",
        "Curtin Dubai",
        "Murdoch University Dubai",
      ],
    },
    {
      country: "Singapore",
      universities: [
        "PSB Academy",
        "London School of Business and Finance (LSBF)",
        "Singapore Institute of Management",
        "AT sunrise",
        "Kaplan Higher Education Academy",
      ],
    },
    {
      country: "USA",
      universities: [
        "Arizona State University",
        "Pace University in New York",
        "Simmons University in Boston",
        "University of Connecticut",
        "Adelphi University",
        "American University",
        "Auburn University",
        "Cleveland State University",
        "University of Dayton",
        "University of Illinois at Chicago",
        "University of Illinois at Springfield",
        "UMASS Boston",
        "University of the Pacific",
        "University of South Carolina",
        "Tulane University",
        "Western New England University",
        "University of Wyoming",
        "Auburn University at Montgomery",
        "Florida International University",
        "Louisiana State University",
        "University of Kansas",
        "University of Utah",
        "Gonzaga University",
        "University of Mississippi",
        "Umass Amherst (Live)",
        "Central Methodist University",
        "Edgewood College",
        "University of Lynchburg",
        "Johns Hopkins University",
        "Roosevelt University",
        "Virginia Wesleyan University",
        "Rutgers University - Camden",
        "Bellarmine University",
        "Fairfield University",
        "University of Dubuque",
        "Lewis University",
        "Shenandoah University",
        "Oklahoma City University",
        "University of Saint Mary (Kansas)",
        "Barton College",
        "Belmont University",
        "Randolph College",
        "Tusculum University",
        "Jacksonville University",
        "Palm Beach Atlantic University",
        "Lakeland University",
        "Robert Morris University",
        "Towson University",
        "Wentworth Institute Of Technology",
        "Moravian University",
        "Baylor University",
        "DePaul University",
        "Florida Atlantic University",
        "James Madison University",
        "Lipscomb University",
        "Long Island University Brooklyn",
        "Long Island University - University Post",
        "Texas A&M Corpus Christi",
        "Western Washington University",
        "University of Hartford",
      ],
    },
  ];

  const [activeFilter, setActiveFilter] = useState(unveristyData[0].country);
  const [universities, setUniversities] = useState([]);

  const filterUniversities = (continent) => {
    const filter = unveristyData.find((obj) => obj.country === continent);
    return filter ? setUniversities(filter.universities) : setUniversities([]);
  };

  useEffect(() => {
    filterUniversities(activeFilter);
  }, [activeFilter]);
  return (
    <section className="my-10 flex flex-col items-center justify-start ">
      <div className="w-[90%] h-full font-Jakarta flex flex-col space-y-8">
        <div className="flex flex-row flex-wrap items-center justify-between">
          <motion.h2
            initial={{ opacity: 0, translateY: 150 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            viewport={{ once: false }}
            transition={{ ease: "easeInOut", duration: 0.8 }}
            className="lg:text-left xl:text-left md:text-left text-4xl md:text-5xl text-center  leading-snug font-semibold"
            style={{ lineHeight: 1.3 }}
          >
            Discover Your Ideal Study
            <br />
            <span className="text-text"> Abroad Destination</span>
          </motion.h2>
          <p className="w-full md:w-full lg:w-[40%] text-[20px] text-text">
            Unlock a world of opportunities tailored just for you with Benchmark
            Education. Let us guide you to the perfect study destination for a
            brighter, successful future.
          </p>
        </div>
        <div className="lg:hidden  block">
          <div className="bg-background py-2 my-5 rounded-lg flex flex-row lg:flex-row md:flex-row xl:flex-row flex-wrap space-x-0 lg:space-x-5 md:space-x-5 justify-start lg:justify-between  px-5">
            <select
              name="country"
              id="country"
              className="w-full bg-transparent py-5 outline-none"
              onChange={(e) => setActiveFilter(e.target.value)}
            >
              {unveristyData.map((obj, index) => (
                <option key={index} value={obj.country}>
                  {obj.country}
                </option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-4 place-items-center gap-10">
            {universities.map((obj, index) => (
              <Link key={index}>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: false }}
                  transition={{ ease: "easeInOut", duration: 0.8 }}
                  className="flex flex-row items-center justify-center gap-5 border w-[145px] lg:w-[250px] md:w-[250px] xl:w-[250px] py-2 rounded-lg cursor-pointer hover:shadow-lg"
                >
                  <h5 className="text-[13px] text-center">{obj}</h5>
                </motion.div>
              </Link>
            ))}
          </div>
        </div>
        <div className="hidden lg:block">
          <Tabs value={activeFilter}>
            <TabsHeader>
              {unveristyData.map((obj, index) => (
                <Tab key={index} value={obj.country}>
                  {obj.country}
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody>
              {unveristyData.map((obj, index) => (
                <TabPanel key={index} value={obj.country}>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-10">
                    {obj.universities.map((item, index) => (
                      <Link>
                        <motion.div
                          initial={{ opacity: 0, scale: 0.5 }}
                          whileInView={{ opacity: 1, scale: 1 }}
                          viewport={{ once: false }}
                          transition={{ ease: "easeInOut", duration: 0.8 }}
                          className="flex flex-row items-center justify-center space-x-5 border w-[150px] lg:w-[350px] md:w-[250px] xl:w-[350px] py-2 rounded-lg cursor-pointer hover:shadow-lg"
                        >
                          <h5 className="font-medium text-[16px] text-center capitalize">
                            {item}
                          </h5>
                        </motion.div>
                      </Link>
                    ))}
                  </div>
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </div>
      </div>
      <div></div>
    </section>
  );
};

export default Universities;
