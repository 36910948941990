import React from 'react'
import { Helmet } from 'react-helmet'
import {motion} from 'framer-motion'
import { AdjustmentsVerticalIcon, BriefcaseIcon, CheckBadgeIcon, CheckCircleIcon, DocumentTextIcon, PencilIcon, SpeakerWaveIcon } from '@heroicons/react/24/solid'

const SOP = () => {
    const data = {
        "id": "2",
        "heading": "SOP Evaluation & Counseling",
        "sections": [
          {
            "id": "2.1",
            "title": "Creating a Winning SOP",
            "description": "A well-crafted Statement of Purpose (SOP) can be the key to distinguishing yourself in competitive university applications. Our experts help you write and refine an SOP that effectively communicates your academic and professional achievements, personal story, and career aspirations. We focus on making your SOP compelling, clear, and relevant to the program you're applying for.",
            "icon": PencilIcon,
            "bgColor": "#FFEBEE"
          },
          {
            "id": "2.2",
            "title": "Individualized Feedback",
            "description": "Each SOP is unique, and we provide detailed, personalized feedback on multiple aspects, including structure, narrative flow, and language. Our counsellors will work with you to highlight your strengths, showcase your academic and professional accomplishments, and create a coherent story that resonates with the admissions committee.",
            "icon": SpeakerWaveIcon,
            "bgColor": "#E3F2FD"
          },
          {
            "id": "2.3",
            "title": "Tailored SOPs for Different Institutions",
            "description": "We ensure that each SOP is tailored to the specific program and university you are applying to, addressing the unique values, expectations, and research interests of the institutions.",
            "icon": BriefcaseIcon,
            "bgColor": "#E8F5E9"
          }
        ],
        "steps": [
          {
            "id": "2.4",
            "title": "SOP Drafting",
            "description": "Initial consultation to understand your personal, academic, and career history to draft a compelling SOP.",
            "icon": DocumentTextIcon,
            "bgColor": "#FFFDE7"
          },
          {
            "id": "2.5",
            "title": "Detailed Review",
            "description": "Comprehensive feedback on narrative flow, strengths, and areas for improvement.",
            "icon": CheckCircleIcon,
            "bgColor": "#E1F5FE"
          },
          {
            "id": "2.6",
            "title": "Finalization",
            "description": "Fine-tune the SOP to reflect your unique qualifications and align with university-specific requirements.",
            "icon": AdjustmentsVerticalIcon,
            "bgColor": "#FCE4EC"
          }
        ]
      }
  return (
    <>
    <Helmet>
      <title>Career & Course Orientation</title>
    </Helmet>
    <section className=" flex flex-col items-center my-10">
      <div className="w-[90%] flex flex-col space-y-10 ">
        <motion.h2
          initial={{ translateY: -20, opacity: 0 }}
          whileInView={{ opacity: 1, translateY: 0 }}
          viewport={{ once: true }}
          transition={{ ease: "linear", duration: 0.8 }}
          className="text-[30px] lg:text-[50px] md:text-[50px] leading-snug font-semibold z-20 text-center"
        >
          {data.heading}
        </motion.h2>

        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-1 gap-5">
          {data.sections.map((obj, index) => (
            <motion.div
              key={index} // Add a unique key to each mapped item
              initial={{ opacity: 0, translateY: 150 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              viewport={{ once: false }}
              transition={{ ease: "easeInOut", duration: 0.8 }}
              className="lg:h-[350px] h-[500px] w-full px-5 py-10 border rounded-lg border-l-4 border-r-4 border-r-primary border-l-primary flex flex-col items-start justify-center"
            >
              <div className="flex flex-col items-center space-y-5">
                <CheckBadgeIcon className="h-8 w-8 fill-primary" />
                <h3 className="font-bold">{obj.title}</h3>
                <p>{obj.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
    <section className="min-h-screen flex flex-col items-center justify-center">
      <div className="w-[90%] flex flex-col space-y-3 bg-sec">
        <motion.h2
          initial={{ translateY: -20, opacity: 0 }}
          whileInView={{ opacity: 1, translateY: 0 }}
          viewport={{ once: true }}
          transition={{ ease: "linear", duration: 0.8 }}
          className="text-[30px] lg:text-[50px] md:text-[50px] leading-snug font-semibold z-20 text-center"
        >
          Steps
          <span className="text-text"> Involved</span>
        </motion.h2>

        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 z-20 gap-5 my-5 font-Jakarta  place-items-center items-center justify-center ">
          {data.steps.map((service, index) => {
            return (
              <motion.div
                key={index}
                initial={{ opacity: 0, translateY: 120 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                viewport={{ once: false }}
                transition={{ ease: "easeInOut", duration: 0.8 }}
                className="col-span-1  shadow-lg rounded-xl bg-white z-20 lg:h-[320px] h-fit "
              >
                <div className="before:h-[160px] before:bg-[#f4f8fa] before:w-[70px] before:absolute before:-rotate-45 before:overflow-hidden before:-z-[1] before:-top-[39px] before:left-0 before:rounded-[35px] flex flex-col items-start justify-center relative p-[42px] overflow-hidden rounded-xl">
                  <div
                    className={` p-3 rounded-full my-2`}
                    style={{ backgroundColor: `${service.bgColor}` }}
                  >
                    <service.icon className="h-5 w-5 stroke-white " />
                  </div>
                  <div className="flex flex-col space-y-5">
                    <h4 className="text-[24px] font-semibold">
                      {service.title}
                    </h4>
                    <p className="text-[16px] text-text">
                      {service.description}
                    </p>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    </section>
  </>
  )
}

export default SOP